import resetCss from 'bundle-text:../../sass/modules/reset.scss';
import artworkCss from 'bundle-text:../../sass/modules/artwork.scss';

import Random from './random.js';
import Color from './color.js';
import Title from './title.js';
import { drawIcon } from './icon.js';

//##//
global.oid = null;
//##//

[resetCss, artworkCss].forEach((css) => {
	const style = document.createElement('style');
	style.innerHTML = css;
	document.head.appendChild(style);
});

const seed = '0x5cfcf4';
const random = Random.init(seed);

const config = {
	MAX_LEVELS: 5,
	MICRO_CLUSTER_PROBABILITY: 0.05,
};

const CONTENT_TYPE = {
	NONE: 0,
	RADIO_BUTTON: 1,
	SELECT: 2,
	INPUT: 3,
	BUTTON: 4,
	IMAGE: 5,
};

const forceLinks = [
	66, 145, 239, 305, 330, 380, 401, 487, 516, 754, 786, 791, 935,
];

export const ids = Array(1000).fill(0).map(generateID);
const titles = Title.generate(1000);

export const id = global.oid || getID();

export const models = addDistances(
	addTitles(
		addColorScore(
			addCoverageScore(
				addComplexityScore(
					ids.reduce((acc, id) => {
						acc[id] = generatePage(id);
						return acc;
					}, {})
				)
			)
		)
	),
	id
);

export const container = createContainer();
export const title = models[id].title;
export const links = [];

function filterModelIDs(models, _axis, _direction) {
	return Object.values(models)
		.filter(
			({ axis, direction }) => axis === _axis && direction === _direction
		)
		.sort((a, b) => a.distance - b.distance)
		.map(({ id }) => id);
}

const neighbors = {
	complexity: {
		prev: filterModelIDs(models, 'complexity', 'less'),
		next: filterModelIDs(models, 'complexity', 'greater'),
	},
	coverage: {
		prev: filterModelIDs(models, 'coverage', 'less'),
		next: filterModelIDs(models, 'coverage', 'greater'),
	},
	color: {
		prev: filterModelIDs(models, 'color', 'less'),
		next: filterModelIDs(models, 'color', 'greater'),
	},
	force: [],
};

if (forceLinks.includes(ids.indexOf(id) + 1)) {
	neighbors.force = [ids[ids.indexOf(id) + 10]];
} else if (forceLinks.includes(ids.indexOf(id))) {
	neighbors.force = [ids[ids.indexOf(id) - 10]];
}

renderPage(
	models[id],
	container,
	Object.keys(models).reduce((acc, key) => {
		acc[key] = models[key].mainColor;
		return acc;
	}, {}),
	neighbors
);

Object.keys(models).forEach((key) => {
	let colors = [];

	if (models[key].elements.visibility)
		colors.push(models[key].elements.colors.background);

	colors.push(
		...models[key].elements.descendants
			.filter((d) => d.visibility)
			.map((d) => d.colors.background)
	);

	models[key].colors = colors;

	delete models[key].elements;
});

function addDistances(models, id) {
	Object.keys(models)
		.map((key) => {
			delete models[key].distance;
			delete models[key].axis;
			delete models[key].direction;

			return models[key];
		})
		.filter((model) => model.id !== id)
		.forEach((model) => {
			const distances = [
				{
					axis: 'complexity',
					distance: Math.abs(
						model.complexity - models[id].complexity
					),
				},
				{
					axis: 'coverage',
					distance: Math.abs(model.coverage - models[id].coverage),
				},
				{
					axis: 'color',
					distance: Math.sqrt(
						Math.pow(
							model.mainColor.r / 255 -
								models[id].mainColor.r / 255,
							2
						) +
							Math.pow(
								model.mainColor.g / 255 -
									models[id].mainColor.g / 255,
								2
							) +
							Math.pow(
								model.mainColor.b / 255 -
									models[id].mainColor.b / 255,
								2
							)
					),
				},
			];

			const distance = distances.reduce(
				(a, { axis, distance }) => a + distance,
				0
			);

			const axis = distances.sort((a, b) => a.distance - b.distance)[0]
				.axis;
			const direction =
				model[axis] > models[id][axis] ? 'greater' : 'less';

			model.distance = distance;
			model.axis = axis;
			model.direction = direction;
		});

	return models;
}

function addTitles(models) {
	Object.keys(models).forEach((key, index) => {
		models[key].title = titles[index];
	});

	return models;
}

function addColorScore(models) {
	Object.keys(models).forEach((key) => {
		models[key].color = Color.rgbToHsv(models[key].mainColor).h;
	});

	const colorScores = Object.keys(models).map((key) => models[key].color);

	const min = Math.min(...colorScores);
	const max = Math.max(...colorScores);

	Object.keys(models).forEach((key) => {
		models[key].color = (models[key].color - min) / (max - min);
	});

	return models;
}

function calculateVisibleBasis(root) {
	let totalVisibleBasis = 0;

	function traverse(node, parentBasis) {
		if (node.visibility && node.basis !== 1) {
			totalVisibleBasis +=
				node.basis *
				parentBasis *
				node.coverage *
				(node.alignment !== 0 ? 0.5 : 1);
		} else {
			parentBasis *= node.basis;

			for (const child of node.children) {
				traverse(child, parentBasis);
			}
		}
	}

	const maxCoverage = Math.min(1, 1 - (root.margin - 70) / 30) * 0.4 + 0.6;

	traverse(root, maxCoverage);

	return totalVisibleBasis;
}

function addCoverageScore(models) {
	Object.keys(models).forEach((key) => {
		const elements = models[key].elements;

		models[key].coverage = calculateVisibleBasis(elements);
	});

	const coverageRange = Object.keys(models).reduce(
		(acc, key) => {
			const coverage = models[key].coverage;
			acc.min = Math.min(acc.min, coverage);
			acc.max = Math.max(acc.max, coverage);
			return acc;
		},
		{ min: Infinity, max: -Infinity }
	);

	Object.keys(models).forEach((key) => {
		models[key].coverage =
			(models[key].coverage - coverageRange.min) /
			(coverageRange.max - coverageRange.min);

		models[key].coverage = Math.max(
			0.001,
			Math.min(0.999, models[key].coverage)
		);
	});

	return models;
}

function addComplexityScore(models) {
	Object.keys(models).forEach((key) => {
		models[key].complexity = models[key].elements.descendants.length;
	});

	const complexityRange = Object.keys(models).reduce(
		(acc, key) => {
			const complexity = models[key].complexity;
			acc.min = Math.min(acc.min, complexity);
			acc.max = Math.max(acc.max, complexity);
			return acc;
		},
		{ min: Infinity, max: -Infinity }
	);

	Object.keys(models).forEach((key) => {
		models[key].complexity = Math.pow(
			(models[key].complexity - complexityRange.min) /
				(complexityRange.max - complexityRange.min),
			0.5
		);

		models[key].coverage = Math.max(
			0.001,
			Math.min(0.999, models[key].coverage)
		);
	});

	return models;
}

function createContainer() {
	const container = document.createElement('div');
	container.classList.add('container');
	document.body.appendChild(container);

	return container;
}

function getID() {
	let id = getURLParameter('id');

	if (!id || !ids.includes(id)) {
		id = ids[911];
		window.history.replaceState({}, '', `?id=${id}`);
	}

	return id;
}

function getURLParameter(name) {
	return (
		decodeURIComponent(
			(window.location.search.match(
				new RegExp('(?:[?|&]' + name + '=)([^&]+)')
			) || [, ''])[1].replace(/\+/g, '%20')
		) || null
	);
}

function generateID() {
	let str = '';
	for (let i = 0; i < 16; i++) {
		str += random.rangeInteger(0, 256).toString(16).padStart(2, '0');
	}
	return str;
}

function renderPage(model, container, networkColors, neighbors) {
	container.innerHTML = '';

	document.title = 'Web — ' + model.title;

	document.body.style.backgroundColor = Color.stringify(model.mainColor);

	const counter = {
		complexity: { prev: 0, next: 0 },
		coverage: { prev: 0, next: 0 },
		force: 0,
	};

	model.elements.descendants.forEach((element) => {
		if (!element.visibility) return;

		let link = null;

		if (element.content !== CONTENT_TYPE.NONE) {
			if (counter.force < neighbors.force.length) {
				link = neighbors.force[counter.force];

				counter.force++;

				links.push(link);
			} else {
				switch (element.content) {
					case CONTENT_TYPE.RADIO_BUTTON:
						link =
							neighbors.complexity.prev[
								counter.complexity.prev %
									neighbors.complexity.prev.length
							];
						counter.complexity.prev++;
						break;
					case CONTENT_TYPE.SELECT:
						link =
							neighbors.complexity.next[
								counter.complexity.next %
									neighbors.complexity.next.length
							];
						counter.complexity.next++;
						break;
					case CONTENT_TYPE.INPUT:
						link =
							neighbors.coverage.prev[
								counter.coverage.prev %
									neighbors.coverage.prev.length
							];
						counter.coverage.prev++;
						break;
					case CONTENT_TYPE.BUTTON:
						link =
							neighbors.coverage.next[
								counter.coverage.next %
									neighbors.coverage.next.length
							];
						counter.coverage.next++;
						break;
					case CONTENT_TYPE.IMAGE:
						const color = element.colors.background;

						const closest = Object.keys(networkColors).reduce(
							(acc, key) => {
								const otherColor = networkColors[key];

								const distance = Color.distanceSquared(
									color,
									otherColor
								);

								if (
									distance < acc.distance &&
									key != model.id
								) {
									acc.distance = distance;
									acc.color = color;
									acc.id = key;
								}

								return acc;
							},
							{ distance: Infinity, color: null, id: null }
						);

						link = closest.id;
						break;
				}
			}
		}

		if (link) {
			element.link = link;
			links.push(link);
		}
	});

	renderElement(container, model.elements, 0);
}

function renderElement(container, model, level) {
	const element = document.createElement('div');
	element.classList.add('element');

	element.style.flexBasis = `${model.basis * 100}%`;
	element.style.flexGrow = 1;
	element.style.flexDirection = level % 2 ? 'column' : 'row';
	element.style.padding = model.padding
		.map((value) => `${value * 100}%`)
		.join(' ');

	element.style.margin = `min(${
		Math.round(model.margin) * 10
	}px, ${Math.round(model.margin)}%)`;
	element.style.width = `${model.coverage * 100}%`;

	if (model.visibility) {
		element.style.backgroundColor = Color.stringify(
			model.colors.background
		);
		element.style.borderWidth = '1px';
		element.style.borderStyle = 'solid';
		element.style.borderColor = `${Color.stringify(
			model.colors.border.top
		)} ${Color.stringify(model.colors.border.right)} ${Color.stringify(
			model.colors.border.bottom
		)} ${Color.stringify(model.colors.border.left)}`;
	}

	if (model.alignment !== 0)
		element.style.alignSelf = ['start', 'center', 'end'][
			model.alignment - 1
		];

	if (model.shadow !== 0 && model.visibility) {
		const shadow = Math.floor(model.shadow * 15 + 5);
		element.style.boxShadow = `${shadow}px ${shadow}px ${
			shadow * 2
		}px #000C`;

		element.style.zIndex = model.zIndex;
	}

	if (model.visibility) {
		let event = null;
		let html = null;

		switch (model.content) {
			case CONTENT_TYPE.NONE:
				break;
			case CONTENT_TYPE.RADIO_BUTTON:
				event = 'change';

				const radioButtons = Array(
					Math.floor(model.contentSize * 3) + 2
				)
					.fill(0)
					.map(() => {
						return `<input type="radio" name="r">`;
					});

				radioButtons[
					Math.floor(model.contentValue * radioButtons.length)
				] = `<input type="radio" name="r" checked>`;

				html = `<form>${radioButtons.join('')}</form>`;

				break;
			case CONTENT_TYPE.SELECT:
				event = 'change';

				html = `<select style="background-color: ${Color.stringify(
					model.colors.content.high
				)}"">${Array.from({
					length: Math.floor(model.contentSize * 15 + 2),
				})
					.fill('<option></option>')
					.join('')}</select>`;

				break;
			case CONTENT_TYPE.INPUT:
				event = 'click';

				html = `<input type="text" style="width: ${
					18 * (Math.floor(model.contentSize * 6) + 3)
				}px; height: 18px; background-color: ${Color.stringify(
					model.colors.content.high
				)}; border-top-color: ${Color.stringify(
					model.colors.content.high
				)}; border-left-color: ${Color.stringify(
					model.colors.content.high
				)}; border-right-color: ${Color.stringify(
					model.colors.content.low
				)}; border-bottom-color: ${Color.stringify(
					model.colors.content.low
				)};">`;

				break;
			case CONTENT_TYPE.BUTTON:
				event = 'click';

				html = `<button style="width: ${
					18 * (Math.floor(model.contentSize * 4) + 1)
				}px; height: 18px; background-color: ${Color.stringify(
					model.colors.content.low
				)}; border-top-color: ${Color.stringify(
					model.colors.content.high
				)}; border-left-color: ${Color.stringify(
					model.colors.content.high
				)}; border-right-color: ${Color.stringify(
					model.colors.content.low
				)}; border-bottom-color: ${Color.stringify(
					model.colors.content.low
				)};"></button>`;

				break;
			case CONTENT_TYPE.IMAGE:
				if (global.oid === null) {
					html = `<a href=${
						model.link ? `?id=${model.link}` : '#'
					} style="display: block; border: none;"><img src="#" style="width: 100%; height: 100%;"></a>`;
				} else {
					html = `<img src="#" style="width: 100%; height: 100%;">`;
				}
				break;
		}

		if (html !== null) {
			element.innerHTML = html;

			if (model.link && event !== null && global.oid === null) {
				element.children[0].addEventListener(event, (e) => {
					window.location.href = `?id=${model.link}`;
				});
			}
		}
	}

	container.appendChild(element);

	model.children.forEach((child) => {
		renderElement(element, child, level + 1);
	});
}

function generatePage(seed) {
	const random = Random.init(seed);
	const color = Color.init(random);

	const size = random.value();

	const params = {
		margin: { value: size > 0.5 ? size : 0, min: 0, max: 10 },
		variance: { value: random.value(), min: 0, max: 1 },

		fragmentation: { value: random.value(), min: 2, max: 5 },
		distribution: { value: random.value(), min: 0, max: 1 },
		padding: { value: size > 0.5 ? random.value() : 0, min: 0, max: 0.05 },
		alignment: { value: random.value(), min: 0, max: 1 },
		filtering: { value: random.value(), min: 0, max: 1 },

		raise: { value: random.value(), min: 0, max: 3 },
		shadow: { value: random.value(), min: 0, max: 1 },
		coverage: { value: random.value(), min: 0.5, max: 1.0 },
	};

	const tree = generateElement(params, 0);

	if (!tree.descendants.some((element) => element.visibility)) {
		const randomElement = random.pick(tree.descendants);
		randomElement.visibility = true;
	}

	if (
		!tree.descendants.some(
			(element) => element.content !== 0 && element.visibility
		)
	) {
		const randomElement = random.pick(tree.descendants);
		randomElement.content = random.rangeInteger(1, 5);
	}

	const model = {
		id: seed,
		mainColor: color.set().low,
		elements: tree,
	};

	return model;

	function varyParameter(value, variance) {
		const min = Math.max(0, value - variance / 2);
		const max = Math.min(1, value + variance / 2);

		return random.range(min, max);
	}

	function mapParameter(value, min, max) {
		return value * (max - min) + min;
	}

	function floatParameter(parameter, variance) {
		return mapParameter(
			varyParameter(parameter.value, variance),
			parameter.min,
			parameter.max
		);
	}

	function intParameter(parameter, variance) {
		return Math.floor(
			mapParameter(
				varyParameter(parameter.value, variance),
				parameter.min,
				parameter.max + 1
			)
		);
	}

	function generateElement(params, level, forceMicroCluster = false) {
		if (
			level >=
			(forceMicroCluster
				? config.MAX_LEVELS + 1
				: random.rangeInteger(3, config.MAX_LEVELS))
		)
			return;

		const elements = [];
		const isRaisedElement = level === intParameter(params.raise, 0);
		let elementCount = random.rangeInteger(
			forceMicroCluster ? 1 : 0,
			8 - level
		);

		if (level === 0 && elementCount === 0)
			elementCount = random.rangeInteger(1, params.fragmentation.max);

		const isDeepestElement =
			level === config.MAX_LEVELS - 1 || elementCount === 0;
		const isAlignedElement = random.value() * level > random.value() * 2;

		for (let i = 0; i < elementCount; i++) {
			const element = generateElement(
				params,
				level + 1,
				forceMicroCluster ||
					random.probability(config.MICRO_CLUSTER_PROBABILITY)
			);

			if (element) elements.push(element);
		}

		const distribution = floatParameter(
			params.distribution,
			params.variance.value
		);

		let bases = Array(elementCount)
			.fill(0)
			.map(() => random.range(0, 1));

		const sum = bases.reduce((a, b) => a + b, 0);

		bases = bases
			.map((value) => value / sum)
			.map(
				(value) =>
					value * distribution +
					(1 / elementCount) * (1 - distribution)
			);

		elements.forEach((element, index) => {
			element.basis = bases[index];
		});

		const padding = Array(4)
			.fill(0)
			.map(() => {
				return floatParameter(params.padding, params.variance.value);
			});

		const alignment = isAlignedElement ? random.rangeInteger(1, 3) : 0;

		let visibility = true;

		if (forceMicroCluster) {
			visibility = true;
		} else if (level < 3) {
			visibility = !random.probability(
				floatParameter(params.filtering, params.variance.value)
			);
		} else {
			visibility = random.probability(0.2 * level);
		}

		const shadow = isRaisedElement ? floatParameter(params.shadow, 1) : 0;

		const zIndex = isRaisedElement ? random.rangeInteger(1, 5) : 0;

		let coverage = isRaisedElement ? floatParameter(params.coverage, 1) : 1;

		const content =
			isDeepestElement && !forceMicroCluster
				? random.rangeInteger(0, 5)
				: 0;

		const mainColorSet = color.set();
		const contentColorSet = color.set();

		const colors = {
			background: mainColorSet.mid,
			border: {
				top: level % 2 == 0 ? mainColorSet.high : mainColorSet.low,
				left: level % 2 == 0 ? mainColorSet.high : mainColorSet.low,
				bottom: level % 2 == 0 ? mainColorSet.low : mainColorSet.high,
				right: level % 2 == 0 ? mainColorSet.low : mainColorSet.high,
			},
			content: contentColorSet,
		};

		const self = {
			basis: 1,
			margin: level === 0 ? floatParameter(params.margin, 0) : 0,
			padding,
			alignment,
			visibility,
			shadow,
			parent: null,
			content,
			contentSize: random.value(),
			contentValue: random.value(),
			colors,
			zIndex,
			coverage,
			children: elements,
			link: null,
			descendants: [
				...elements.map((e) => e.descendants),
				...elements,
			].flat(),
		};

		elements.forEach((element) => {
			element.parent = self;
		});

		return self;
	}
}

const faviconCanvas = document.createElement('canvas');
const faviconContext = faviconCanvas.getContext('2d');

faviconCanvas.width = 16;
faviconCanvas.height = 16;

drawIcon(models[id], faviconContext, 0, 0);

const link = document.createElement('link');
link.type = 'image/x-icon';
link.rel = 'shortcut icon';
link.href = faviconCanvas.toDataURL('image/x-icon');
document.getElementsByTagName('head')[0].appendChild(link);
