function cyrb128(str) {
	let h1 = 1779033703,
		h2 = 3144134277,
		h3 = 1013904242,
		h4 = 2773480762;
	for (let i = 0, k; i < str.length; i++) {
		k = str.charCodeAt(i);
		h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
		h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
		h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
		h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
	}
	h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
	h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
	h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
	h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
	return [
		(h1 ^ h2 ^ h3 ^ h4) >>> 0,
		(h2 ^ h1) >>> 0,
		(h3 ^ h1) >>> 0,
		(h4 ^ h1) >>> 0,
	];
}

function sfc32(a, b, c, d) {
	return function () {
		a >>>= 0;
		b >>>= 0;
		c >>>= 0;
		d >>>= 0;
		var t = (a + b) | 0;
		a = b ^ (b >>> 9);
		b = (c + (c << 3)) | 0;
		c = (c << 21) | (c >>> 11);
		d = (d + 1) | 0;
		t = (t + d) | 0;
		c = (c + t) | 0;
		return (t >>> 0) / 4294967296;
	};
}

export default {
	init: (str) => {
		str = str + '';
		const seed = cyrb128(str);

		const randomGenerator = sfc32(seed[0], seed[1], seed[2], seed[3]);

		return {
			value: () => randomGenerator(),
			range: (min = 0, max = 1) => min + randomGenerator() * (max - min),
			rangeInteger: (min = 0, max = 1) =>
				min + Math.round(randomGenerator() * (max - min)),
			pick: (arr) => arr[Math.floor(randomGenerator() * arr.length)],
			probability: (probability) => randomGenerator() < probability,
			shuffle: (arr) => {
				let currentIndex = arr.length,
					temporaryValue,
					randomIndex;

				while (0 !== currentIndex) {
					randomIndex = Math.floor(randomGenerator() * currentIndex);
					currentIndex -= 1;

					temporaryValue = arr[currentIndex];
					arr[currentIndex] = arr[randomIndex];
					arr[randomIndex] = temporaryValue;
				}

				return arr;
			},
		};
	},
};
