function constrain(val, min, max) {
	return Math.min(Math.max(val, min), max);
}

export default {
	init: (random) => {
		let state = {
			r: random.rangeInteger(0, 255),
			g: random.rangeInteger(0, 255),
			b: random.rangeInteger(0, 255),
		};

		let range = { min: 0, max: 255 };
		let maxOffset = random.rangeInteger(50);

		function iterate() {
			let offset = random.rangeInteger(1, maxOffset);

			state.r += random.rangeInteger(-offset, offset);
			state.r = constrain(state.r, range.min, range.max);
			state.g += random.rangeInteger(-offset, offset);
			state.g = constrain(state.g, range.min, range.max);
			state.b += random.rangeInteger(-offset, offset);
			state.b = constrain(state.b, range.min, range.max);
		}

		function generateColorSet() {
			iterate();

			return {
				low: {
					r: constrain(state.r - 30, range.min, range.max),
					g: constrain(state.g - 30, range.min, range.max),
					b: constrain(state.b - 30, range.min, range.max),
				},
				mid: { r: state.r, g: state.g, b: state.b },
				high: {
					r: constrain(state.r + 30, range.min, range.max),
					g: constrain(state.g + 30, range.min, range.max),
					b: constrain(state.b + 30, range.min, range.max),
				},
			};
		}

		function generateColor() {
			iterate();

			const color = { ...state };

			return color;
		}

		return {
			single: () => generateColor(),
			set: () => generateColorSet(),
		};
	},

	stringify: (rgb, alpha = 1) => {
		return `rgba(${rgb.r},${rgb.g},${rgb.b}, ${alpha})`;
	},

	distanceSquared: (rgb1, rgb2) => {
		return (
			Math.pow(rgb1.r - rgb2.r, 2) +
			Math.pow(rgb1.g - rgb2.g, 2) +
			Math.pow(rgb1.b - rgb2.b, 2)
		);
	},

	rgbToHsv: (rgb) => {
		let r = rgb.r / 255;
		let g = rgb.g / 255;
		let b = rgb.b / 255;

		let max = Math.max(r, g, b);
		let min = Math.min(r, g, b);
		let h,
			s,
			v = max;

		let d = max - min;
		s = max == 0 ? 0 : d / max;

		if (max == min) {
			h = 0;
		} else {
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}
			h /= 6;
		}

		return { h, s, v };
	},
};
