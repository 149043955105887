import '../sass/modules/reset.scss';
import '../sass/modules/fonts.scss';
import '../sass/modules/ui.scss';
import '../sass/main.scss';

import { container, models, links, id } from './modules/artwork';
import { drawMap } from './modules/map';
import UI, { getBookmarks } from './modules/ui';

import './modules/web3';

let map = { container: null, map: null, visible: false };

map.show = () => {
	if (!map.map) return;

	map.map.loop();
	map.visible = true;
	map.container.classList.add('visible');

	document.querySelector('.button-explore').classList.add('active');
	// document.querySelector('.network-stats').classList.add('visible');
};

map.hide = () => {
	if (!map.map) return;

	map.map.noLoop();
	map.visible = false;
	map.container.classList.remove('visible');

	document.querySelector('.button-explore').classList.remove('active');
	// document.querySelector('.network-stats').classList.remove('visible');
};

map.toggle = () => (map.visible ? map.hide() : map.show());

const ui = UI(id, models, map);

fetch('discovered', {
	method: 'POST',
})
	.then((response) => response.json())
	.then((discovered) => {
		const bookmarked = Object.keys(discovered).reduce((acc, id) => {
			if (discovered[id].b > 0) acc += 1;
			return acc;
		}, 0);

		const discoveredIds = Object.keys(discovered);

		// document.querySelector('.network-stat-discovered .value').innerHTML =
		// 	Math.floor((discoveredIds.length / 1000) * 100) + '%';

		// document.querySelector(
		// 	'.network-stat-discovered .graph-fill'
		// ).style.width = (discoveredIds.length / 1000) * 100 + '%';

		// document.querySelector('.network-stat-bookmarked .value').innerHTML =
		// 	Math.floor((bookmarked / 1000) * 100) + '%';

		// document.querySelector(
		// 	'.network-stat-bookmarked .graph-fill'
		// ).style.width = (bookmarked / 1000) * 100 + '%';

		links.forEach((link) => {
			if (!discoveredIds.includes(link)) {
				discovered[link] = { v: 0, b: 0, p: false };
				discoveredIds.push(link);
			}
		});

		if (!discoveredIds.includes(id)) {
			discovered[id] = { v: 0, b: 0, p: false };
			discoveredIds.push(id);
		}

		map.container = document.createElement('div');
		map.container.classList.add('map-container');

		container.appendChild(map.container);

		const bookmarks = getBookmarks();

		const discoveredModels = discoveredIds.reduce((acc, key) => {
			acc[key] = { ...models[key] };
			acc[key].link = links.includes(key);
			acc[key].bookmarked = bookmarks.includes(key);
			acc[key].proof = discovered[key].p;

			return acc;
		}, {});

		map.map = drawMap(id, discoveredModels, map.container);
		map.map.noLoop();
	});
