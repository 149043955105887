import p5 from 'p5';

import { getAtlas, textureCoordinate } from './icon';
import Color from './color';
import { ids } from './artwork';
import { mintedIDs } from './ui';
import { getAccount } from '@wagmi/core';

function rotateVector(vector, angleX, angleY) {
	const x = vector[0];
	const y = vector[1];
	const z = vector[2];

	const cosX = Math.cos(angleX);
	const sinX = Math.sin(angleX);

	const cosY = Math.cos(angleY);
	const sinY = Math.sin(angleY);

	return [
		x * cosY + z * sinY,
		x * sinX * sinY + y * cosX - z * sinX * cosY,
		-x * cosX * sinY + y * sinX + z * cosX * cosY,
	];
}

function lighter(hsv) {
	let { h, s, v } = hsv;

	s = 0.2;
	v = 1;

	return { h, s, v };
}

function contrast(hsv) {
	let { h, s, v } = hsv;

	h = (h + 0.5) % 1;

	return { h, s, v };
}

export function drawMap(id, models, container) {
	const sketch = (p) => {
		let atlas;
		let ctx;
		let size = 0;
		let minSize = 1024;
		let maxSize = 4096;

		const camera = { x: p.radians(-22.5), y: p.radians(-45) };
		const cameraSpeed = { x: 0, y: 0 };
		const cameraVelocity = 0.005;
		const cameraDamp = 0.9;

		const xAxis = [];
		const yAxis = [];
		const zAxis = [];

		let highlight = Color.rgbToHsv(models[id].mainColor);

		let breathingSpeed = 0;

		function lineStraight(x1, y1, z1, x2, y2, z2) {
			// Connect these points, only allow one axis to change at a time

			p.line(x1, y1, z1, x2, y1, z1);
			p.line(x2, y1, z1, x2, y2, z1);
			p.line(x2, y2, z1, x2, y2, z2);
		}

		function s() {
			const goldenRatio = 1.61803398875;

			return Math.min(p.height / goldenRatio, p.width / goldenRatio);
			// return minSize + (maxSize - minSize) * size;
		}

		p.setup = () => {
			const c = p.createCanvas(
				container.parentElement.clientWidth,
				container.parentElement.clientHeight,
				p.WEBGL
			);

			ctx = p.drawingContext;

			p.background(0, 0);

			atlas = p.loadImage(getAtlas(models));
			c.getTexture(atlas).setInterpolation(p.NEAREST, p.NEAREST);

			const coneSegments = 16;
			const coneRadiusStart = 0.25;
			const coneRadiusEnd = 5;
			const coneResolution = 16;
			const coneExponent = 5;

			const coneLevels = [];

			for (let i = 0; i < coneSegments; i++) {
				const level = [];

				for (let j = 0; j < coneResolution; j++) {
					const angle = p.radians((360 / coneResolution) * j);
					const radius =
						(coneRadiusEnd - coneRadiusStart) *
							Math.pow(1 - i / (coneSegments - 1), coneExponent) +
						coneRadiusStart;
					const x = (1 / (coneSegments - 1)) * i;
					const y = Math.cos(angle) * radius;
					const z = Math.sin(angle) * radius;

					level.push([x, y, z]);
				}

				coneLevels.push(level);

				p.colorMode(p.HSB, 255);
			}

			for (let i = 0; i < coneSegments; i++) {
				const level = coneLevels[i];
				const nextLevel = coneLevels[i + 1];

				if (nextLevel) {
					for (let j = 0; j < coneResolution; j++) {
						const a = level[j];
						const b = level[(j + 1) % coneResolution];
						const c = nextLevel[(j + 1) % coneResolution];
						const d = nextLevel[j];

						xAxis.push(a, b, c);
						xAxis.push(a, c, d);
					}
				}
			}

			// Copy mirrored vertices
			const xAxisLength = xAxis.length;
			for (let i = 0; i < xAxisLength; i++) {
				const vertex = xAxis[i];
				xAxis.push([-vertex[0], vertex[1], vertex[2]]);
			}

			// Rotate 90 degrees around Y axis
			for (let i = 0; i < xAxis.length; i++) {
				zAxis.push(rotateVector(xAxis[i], 0, p.radians(90)));
			}

			// Rotate 90 degrees around X axis
			for (let i = 0; i < zAxis.length; i++) {
				yAxis.push(rotateVector(zAxis[i], p.radians(90), 0));
			}

			setOrtho();
		};

		function setOrtho() {
			const w = Math.max(1, p.width / 2);
			const h = Math.max(1, p.height / 2);
			p.ortho(-w, w, -h, h, 0.01, 2048);
		}

		p.mouseDragged = (e) => {
			if (p.isLooping() && e.target === p.canvas) {
				cameraSpeed.y = (p.mouseX - p.pmouseX) * cameraVelocity;
				cameraSpeed.x = -((p.mouseY - p.pmouseY) * cameraVelocity);
			}
		};

		// p.mouseWheel = (event) => {
		// 	size += event.delta / 1000;
		// 	size = Math.max(0, Math.min(1, size));
		// };

		p.draw = () => {
			breathingSpeed = Math.max(0, Math.sin(p.frameCount / 240)) * 0.2;

			const curX = models[id].complexity * s() - s() / 2;
			const curY = models[id].coverage * s() - s() / 2;
			const curZ = models[id].color * s() - s() / 2;

			camera.x += cameraSpeed.x;
			camera.y += cameraSpeed.y + p.radians(breathingSpeed);

			cameraSpeed.x *= cameraDamp;
			cameraSpeed.y *= cameraDamp;

			p.background(0, 0);
			p.rotateX(camera.x);
			p.rotateY(camera.y);
			p.translate(-curX * size, -curY * size, -curZ * size);

			p.noStroke();
			p.push();
			// Draw the cone

			// const contrasting = contrast(highlight);
			// p.fill(
			// 	highlight.h * 255,
			// 	Math.min(255, highlight.s * 640),
			// 	highlight.v * 180
			// );
			p.fill(highlight.h * 255, 60, 255);

			p.beginShape(p.TRIANGLES);
			xAxis.forEach((vertex) => {
				p.vertex((vertex[0] * s()) / 2, vertex[1], vertex[2]);
			});
			yAxis.forEach((vertex) => {
				p.vertex(vertex[0], (vertex[1] * s()) / 2, vertex[2]);
			});
			zAxis.forEach((vertex) => {
				p.vertex(vertex[0], vertex[1], (vertex[2] * s()) / 2);
			});
			p.endShape();
			p.pop();

			// const labels = [
			// 	{ text: 'Complexity', x: -s() / 2 - 50, y: 0, z: 0 },
			// 	{ text: 'Coverage', x: 0, y: -s() / 2 - 50, z: 0 },
			// 	{ text: 'Color', x: 0, y: 0, z: -s() / 2 - 50 },
			// ];

			// p.fill(highlight.h * 255, highlight.s * 255, highlight.v * 255);
			// p.textAlign(p.CENTER, p.CENTER);
			// p.textSize(16);

			// labels.forEach((label) => {
			// 	p.push();
			// 	p.translate(label.x, label.y, label.z);
			// 	p.rotateY(-camera.y);
			// 	p.rotateX(-camera.x);
			// 	p.text(label.text, 0, 0, 0);
			// 	p.pop();
			// });

			let coordinates = [
				[-0.5, -0.5, 0],
				[0.5, -0.5, 0],
				[0.5, 0.5, 0],
				[-0.5, 0.5, 0],
			];

			p.push();

			p.translate(curX, curY, curZ);
			p.rotateY(-camera.y);
			p.rotateX(-camera.x);

			let progress = Math.pow((p.frameCount % 180) / 90, 0.2);
			p.fill(
				highlight.h * 255,
				highlight.s * 255,
				highlight.v * 255,
				Math.min(255, (1 - progress) * 1000)
			);
			p.circle(0, 0, progress * 32);

			p.fill(0, 0);
			p.sphere(18);
			p.pop();

			coordinates = coordinates.map((coordinate) =>
				rotateVector(
					rotateVector(coordinate, -camera.x, 0),
					0,
					-camera.y
				)
			);

			p.texture(atlas);
			p.noStroke();
			p.push();
			p.beginShape(p.QUADS);

			const acc = getAccount();

			Object.keys(mintedIDs.ids).forEach((id) => {
				const addr = mintedIDs.ids[id];

				models[id].owned = addr === acc.address;
			});

			Object.values(models)
				.filter(
					(model) =>
						model.id !== id && !model.bookmarked && !model.owned
				)
				.forEach((model, index) => {
					const [tx, ty] = textureCoordinate(ids.indexOf(model.id));

					const x = model.complexity * s() - s() / 2;
					const y = model.coverage * s() - s() / 2;
					const z = model.color * s() - s() / 2;

					const size = model.id === id ? 16 : 8;

					p.vertex(
						x + coordinates[0][0] * size,
						y + coordinates[0][1] * size,
						z + coordinates[0][2] * size,
						tx,
						ty
					);
					p.vertex(
						x + coordinates[1][0] * size,
						y + coordinates[1][1] * size,
						z + coordinates[1][2] * size,
						tx + 16,
						ty
					);
					p.vertex(
						x + coordinates[2][0] * size,
						y + coordinates[2][1] * size,
						z + coordinates[2][2] * size,
						tx + 16,
						ty + 16
					);
					p.vertex(
						x + coordinates[3][0] * size,
						y + coordinates[3][1] * size,
						z + coordinates[3][2] * size,
						tx,
						ty + 16
					);
				});
			p.endShape();
			p.pop();

			const bookmarks = Object.values(models).filter(
				(model) => model.bookmarked && model.id !== id && !model.owned
			);

			p.push();
			p.noFill();
			p.stroke(191, 176, 255, 255);
			p.strokeWeight(2);
			p.strokeCap(p.PROJECT);
			p.strokeJoin(p.MITER);

			p.beginShape(p.QUADS);
			bookmarks.forEach((model, index) => {
				const x = model.complexity * s() - s() / 2;
				const y = model.coverage * s() - s() / 2;
				const z = model.color * s() - s() / 2;

				p.vertex(
					x + coordinates[0][0] * 28,
					y + coordinates[0][1] * 28,
					z + coordinates[0][2] * 28
				);
				p.vertex(
					x + coordinates[1][0] * 28,
					y + coordinates[1][1] * 28,
					z + coordinates[1][2] * 28
				);
				p.vertex(
					x + coordinates[2][0] * 28,
					y + coordinates[2][1] * 28,
					z + coordinates[2][2] * 28
				);
				p.vertex(
					x + coordinates[3][0] * 28,
					y + coordinates[3][1] * 28,
					z + coordinates[3][2] * 28
				);
			});
			p.endShape();

			p.texture(atlas);
			p.noStroke();

			p.beginShape(p.QUADS);
			bookmarks.forEach((model, index) => {
				const [tx, ty] = textureCoordinate(ids.indexOf(model.id));

				const x = model.complexity * s() - s() / 2;
				const y = model.coverage * s() - s() / 2;
				const z = model.color * s() - s() / 2;

				const size = model.id === id ? 16 : 8;

				p.vertex(
					x + coordinates[0][0] * size * 3,
					y + coordinates[0][1] * size * 3,
					z + coordinates[0][2] * size * 3,
					tx,
					ty
				);
				p.vertex(
					x + coordinates[1][0] * size * 3,
					y + coordinates[1][1] * size * 3,
					z + coordinates[1][2] * size * 3,
					tx + 16,
					ty
				);
				p.vertex(
					x + coordinates[2][0] * size * 3,
					y + coordinates[2][1] * size * 3,
					z + coordinates[2][2] * size * 3,
					tx + 16,
					ty + 16
				);
				p.vertex(
					x + coordinates[3][0] * size * 3,
					y + coordinates[3][1] * size * 3,
					z + coordinates[3][2] * size * 3,
					tx,
					ty + 16
				);
			});
			p.endShape();
			p.pop();

			const owned = Object.values(models).filter(
				(model) => model.owned && model.id !== id
			);

			p.push();
			p.noFill();
			p.stroke(120, 255, 255);
			p.strokeWeight(2);
			p.strokeCap(p.PROJECT);
			p.strokeJoin(p.MITER);

			p.beginShape(p.QUADS);
			owned.forEach((model, index) => {
				const x = model.complexity * s() - s() / 2;
				const y = model.coverage * s() - s() / 2;
				const z = model.color * s() - s() / 2;

				p.vertex(
					x + coordinates[0][0] * 28,
					y + coordinates[0][1] * 28,
					z + coordinates[0][2] * 28
				);
				p.vertex(
					x + coordinates[1][0] * 28,
					y + coordinates[1][1] * 28,
					z + coordinates[1][2] * 28
				);
				p.vertex(
					x + coordinates[2][0] * 28,
					y + coordinates[2][1] * 28,
					z + coordinates[2][2] * 28
				);
				p.vertex(
					x + coordinates[3][0] * 28,
					y + coordinates[3][1] * 28,
					z + coordinates[3][2] * 28
				);
			});
			p.endShape();

			p.texture(atlas);
			p.noStroke();

			p.beginShape(p.QUADS);
			owned.forEach((model, index) => {
				const [tx, ty] = textureCoordinate(ids.indexOf(model.id));

				const x = model.complexity * s() - s() / 2;
				const y = model.coverage * s() - s() / 2;
				const z = model.color * s() - s() / 2;

				const size = model.id === id ? 16 : 8;

				p.vertex(
					x + coordinates[0][0] * size * 3,
					y + coordinates[0][1] * size * 3,
					z + coordinates[0][2] * size * 3,
					tx,
					ty
				);
				p.vertex(
					x + coordinates[1][0] * size * 3,
					y + coordinates[1][1] * size * 3,
					z + coordinates[1][2] * size * 3,
					tx + 16,
					ty
				);
				p.vertex(
					x + coordinates[2][0] * size * 3,
					y + coordinates[2][1] * size * 3,
					z + coordinates[2][2] * size * 3,
					tx + 16,
					ty + 16
				);
				p.vertex(
					x + coordinates[3][0] * size * 3,
					y + coordinates[3][1] * size * 3,
					z + coordinates[3][2] * size * 3,
					tx,
					ty + 16
				);
			});
			p.endShape();
			p.pop();

			// p.stroke(highlight.h * 255, highlight.s * 255, highlight.v * 255);
			p.stroke(highlight.h * 255, 25, 255);
			p.strokeWeight(1);
			p.noFill();

			Object.values(models)
				.filter((model) => model.link && model.id !== id)
				.forEach((model) => {
					if (model.link) {
						const x = model.complexity * s() - s() / 2;
						const y = model.coverage * s() - s() / 2;
						const z = model.color * s() - s() / 2;

						// Draw a bezier from x, y, z to curX, curY, curZ
						// p.bezier(
						// 	x,
						// 	y,
						// 	z,
						// 	x + (curX - x) * 0.5,
						// 	y + (curY - y) * 0.5,
						// 	z + (curZ - z) * 0.1,
						// 	curX + (x - curX) * 0.5,
						// 	curY + (y - curY) * 0.5,
						// 	curZ + (z - curZ) * 0.1,
						// 	curX,
						// 	curY,
						// 	curZ
						// );

						// p.line(x, y, z, curX, curY, curZ);
						lineStraight(x, y, z, curX, curY, curZ);
					}
				});

			ctx.disable(ctx.DEPTH_TEST);
			p.push();
			p.translate(curX, curY, curZ);
			p.rotateY(-camera.y);
			p.rotateX(-camera.x);

			p.noFill();
			p.stroke(255, 0, 255, 255);
			p.strokeWeight(2);
			p.strokeCap(p.PROJECT);
			p.strokeJoin(p.MITER);

			p.rect(-14, -14, 28, 28);

			p.fill(255);
			p.texture(atlas);
			p.noStroke();

			const [tx, ty] = textureCoordinate(ids.indexOf(id));

			p.beginShape(p.QUADS);
			p.vertex(-12, -12, 0, tx, ty);
			p.vertex(12, -12, 0, tx + 16, ty);
			p.vertex(12, 12, 0, tx + 16, ty + 16);
			p.vertex(-12, 12, 0, tx, ty + 16);
			p.endShape();
			// p.fill(highlight.h * 255, highlight.s * 255, highlight.v * 255);
			// p.noStroke();
			// p.circle(0, 0, 10);
			p.pop();
			ctx.enable(ctx.DEPTH_TEST);
		};

		p.windowResized = () => {
			p.resizeCanvas(
				container.parentElement.clientWidth,
				container.parentElement.clientHeight
			);
			setOrtho();
		};
	};

	const p = new p5(sketch, container);

	return p;
}
