import { id, ids, models } from './artwork';
import { getAtlas, textureCoordinate } from './icon';
import {
	connectButtonClicked,
	contractAddress,
	disconnectButtonClicked,
} from './web3';
import { getAccount } from '@wagmi/core';
// import config from '../../../config/config';

// const time = { start: null, end: null };
export const mintedIDs = { ids: {} };
const allIDs = ids;

let atlas = null;

export function setMintedIDs(ids) {
	const account = getAccount().address;

	let nOwned = 0;

	if (!account) {
		document.querySelector(
			'.overlay-bookmarks .overlay-title-2'
		).style.display = 'none';
		document.querySelector('.overlay-bookmarks .mints').style.display =
			'none';
	} else {
		document.querySelector(
			'.overlay-bookmarks .overlay-title-2'
		).style.display = 'flex';

		const container = document.querySelector('.overlay-bookmarks .mints');

		container.style.display = 'block';

		container.innerHTML = '';

		Object.keys(ids).forEach((id) => {
			if (ids[id] === account) {
				nOwned++;
				const model = models[id];

				const bookmark = document.createElement('div');
				bookmark.classList.add('bookmark');
				bookmark.dataset.id = id;

				const coords = textureCoordinate(allIDs.indexOf(id));

				bookmark.innerHTML = `
		<div class="bookmark-icon"
			style="background-image: url(${atlas}); background-position: -${coords[0]}px -${coords[1]}px;"></div>
		<div class="bookmark-metadata">
		<div class="bookmark-title">${model.title}</div>
		</div>
		`;

				container.appendChild(bookmark);

				bookmark.addEventListener('click', () => {
					window.location.href = `?id=${id}`;
				});
			}
		});
	}

	if (nOwned === 0) {
		document.querySelector(
			'.overlay-bookmarks .overlay-title-2'
		).style.display = 'none';
		document.querySelector('.overlay-bookmarks .mints').style.display =
			'none';
	}

	// const percentage = Math.round((Object.keys(ids).length / 1000) * 100);
	// document.querySelector('.network-stat-minted .value').innerHTML =
	// 	percentage + '%';

	// document.querySelector('.network-stat-minted .graph-fill').style.width =
	// 	percentage + '%';

	mintedIDs.ids = ids;

	if (mintedIDs.ids[id]) {
		// hideMintButton();

		if (mintedIDs.ids[id] === account) {
			document.querySelector('.header-lower').classList.add('minted');
		}

		document.querySelector(
			'.minted-by'
		).innerHTML = `Owned by 0x…${mintedIDs.ids[id].slice(
			-4
		)} | View on <a target="_blank" href='https://opensea.io/assets/${contractAddress}/${allIDs.indexOf(
			id
		)}'>OpenSea</a>`;
	} else {
		document.querySelector('.header-lower').classList.remove('minted');
	}

	document.querySelectorAll('.bookmark').forEach((bookmark) => {
		const id = bookmark.dataset.id;

		if (mintedIDs.ids[id]) bookmark.classList.add('minted');
		else bookmark.classList.remove('minted');
	});
}

export function showConnectButton(onClick) {
	const container = document.querySelector('.web3-button');
	container.innerHTML = ``;

	const button = document.createElement('button');
	button.classList.add('button-wallet');
	button.classList.add('button-wallet-connect');
	button.textContent = 'Connect Wallet';
	button.addEventListener('click', onClick);

	container.appendChild(button);
}

export function removeConnectButton() {
	const container = document.querySelector('.web3-button');
	if (container && container.firstChild) {
		container.firstChild.remove();
	}
}

export function showDisconnectButton(address, onClick) {
	const container = document.querySelector('.web3-button');
	container.innerHTML = ``;

	const button = document.createElement('button');
	button.classList.add('button-wallet');
	button.classList.add('button-wallet-disconnect');
	button.innerHTML = `<div><span>${address}</span><span>Disconnect</span></div>`;
	button.addEventListener('click', onClick);

	container.appendChild(button);
}

function setTitle(title) {
	document.querySelector('.header-section-title').innerHTML = title;
}

function connectMapButton(map) {
	const button = document.querySelector('.button-explore');

	button.addEventListener('click', () => {
		map.toggle();
	});
}

export function getBookmarks() {
	const bookmarks = localStorage.getItem('bookmarks');
	return bookmarks ? JSON.parse(bookmarks) : [];
}

function addBookmark(id) {
	const bookmarks = getBookmarks();

	if (!bookmarks.includes(id)) {
		bookmarks.push(id);
		localStorage.setItem('bookmarks', JSON.stringify(bookmarks));

		fetch(`/bookmark?id=${id}`, {
			method: 'POST',
		});
	}

	updateBookmarksUI();
}
function connectCloseButton(button) {
	button.addEventListener('click', () => {
		document.querySelectorAll('.overlay.visible').forEach((overlay) => {
			overlay.classList.remove('visible');
		});

		document.querySelector('.ui').classList.remove('overlay-visible');

		['about', 'bookmarks', 'manual'].forEach((section) => {
			document
				.querySelector(`.button-${section}`)
				.classList.remove('active');
		});
	});
}

function removeBookmark(id) {
	const bookmarks = getBookmarks();

	if (bookmarks.includes(id)) {
		bookmarks.splice(bookmarks.indexOf(id), 1);
		localStorage.setItem('bookmarks', JSON.stringify(bookmarks));

		fetch(`/bookmark?id=${id}`, {
			method: 'DELETE',
		});
	}

	updateBookmarksUI();
}

function isBookmarked(id) {
	return getBookmarks().includes(id);
}

function toggleBookmark(id) {
	if (isBookmarked(id)) {
		removeBookmark(id);
		decrementBookmarkCountUI();
	} else {
		addBookmark(id);
		incrementBookmarkCountUI();
	}
}

let lastStats = null;

function updateBookmarksUI(stats) {
	if (!stats && !lastStats) return;
	if (!stats) stats = lastStats;

	const bookmarks = getBookmarks();

	const container = document.querySelector('.overlay-bookmarks .bookmarks');

	container.innerHTML = '';

	bookmarks.forEach((id, index) => {
		const model = models[id];

		const bookmark = document.createElement('div');
		bookmark.classList.add('bookmark');
		bookmark.dataset.id = id;

		if (mintedIDs.ids[id]) bookmark.classList.add('minted');

		const coords = textureCoordinate(ids.indexOf(id));

		bookmark.innerHTML = `
		<div class="bookmark-icon"
			style="background-image: url(${atlas}); background-position: -${coords[0]}px -${coords[1]}px;"></div>
		<div class="bookmark-metadata">
		<div class="bookmark-title">${model.title}</div>
		<div class="stats">
			<div class="stats-views">${stats[id].views}</div>
			<div class="stats-bookmarks">${stats[id].bookmarks}</div>
		</div>
		</div>
		`;

		container.appendChild(bookmark);

		bookmark.addEventListener('click', () => {
			window.location.href = `?id=${id}`;
		});
	});

	lastStats = stats;
}

function updateBookmarkUI(isBookmarked) {
	document
		.querySelector('.header-lower')
		.classList.toggle('bookmarked', isBookmarked);
}

function incrementBookmarkCountUI() {
	const bookmarkCount = document.querySelector('.stats-bookmarks');
	bookmarkCount.textContent = parseInt(bookmarkCount.textContent) + 1;
}

function decrementBookmarkCountUI() {
	const bookmarkCount = document.querySelector('.stats-bookmarks');
	bookmarkCount.textContent = parseInt(bookmarkCount.textContent) - 1;
}

async function getStats(ids) {
	const res = await fetch('/stats', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ ids }),
	});

	return await res.json();
}

export default function init(id, models, map) {
	atlas = getAtlas(models);

	let uiVisible = localStorage.getItem('uiVisible');

	if (uiVisible === null) {
		uiVisible = 'true';
		localStorage.setItem('uiVisible', uiVisible);
	}

	uiVisible = uiVisible === 'true';

	document.querySelector('.ui').classList.toggle('visible', uiVisible);

	setTitle(models[id].title);
	connectMapButton(map);
	updateBookmarkUI(isBookmarked(id));

	document.querySelector('.ui-toggle').addEventListener('click', () => {
		document.querySelector('.ui').classList.toggle('visible');

		// save state to local storage
		const uiVisible = document
			.querySelector('.ui')
			.classList.contains('visible');
		localStorage.setItem('uiVisible', uiVisible);
	});

	document.querySelector('.button-bookmark').addEventListener('click', () => {
		toggleBookmark(id);
		updateBookmarkUI(isBookmarked(id));
	});

	function updateStats() {
		getStats([id, ...getBookmarks()]).then((stats) => {
			const viewCount = document.querySelector('.stats-views');
			const bookmarkCount = document.querySelector('.stats-bookmarks');

			viewCount.textContent = stats[id].views;
			bookmarkCount.textContent = stats[id].bookmarks;

			updateBookmarkUI(isBookmarked(id));
			updateBookmarksUI(stats);
		});
	}

	setInterval(updateStats, 5000);
	updateStats();

	['about', 'bookmarks', 'manual'].forEach((section) => {
		document.querySelectorAll(`.button-${section}`).forEach((button) => {
			button.addEventListener('click', () => {
				const wasVisible = document
					.querySelector(`.overlay-${section}`)
					.classList.contains('visible');

				document
					.querySelectorAll(`.overlay`)
					.forEach((overlay) => overlay.classList.remove('visible'));

				if (!wasVisible) {
					document
						.querySelector(`.overlay-${section}`)
						.classList.add('visible');
				}

				['about', 'bookmarks', 'manual'].forEach((section) => {
					document
						.querySelectorAll(`.button-${section}`)
						.forEach((button) => {
							button.classList.remove('active');
						});
				});

				if (!wasVisible) {
					document
						.querySelectorAll(`.button-${section}`)
						.forEach((button) => {
							button.classList.add('active');
						});
				}

				document
					.querySelector('.ui')
					.classList.toggle('overlay-visible', !wasVisible);
			});
		});
	});

	connectCloseButton(document.querySelector('.overlay-about .button-close'));
	connectCloseButton(
		document.querySelector('.overlay-bookmarks .button-close')
	);
	connectCloseButton(document.querySelector('.overlay-manual .button-close'));

	connectCloseButton(document.querySelector('.ui-toggle'));

	const account = getAccount();

	if (!account.address) {
		showConnectButton(connectButtonClicked);
	} else {
		showDisconnectButton(
			`0x…${account.address.slice(-4)}`,
			disconnectButtonClicked
		);
	}
}
