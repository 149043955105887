import Color from './color';

export function drawIcon(model, context, x, y) {
	context.fillStyle = Color.stringify(model.mainColor, 1);
	context.fillRect(x, y, 16, 16);

	let colors = model.colors;

	context.fillStyle = Color.stringify(colors[0], 1);
	context.fillRect(x + 2, y + 2, 12, 12);

	colors = colors
		.map((color) => {
			const hsv = Color.rgbToHsv(color);

			return { ...color, ...hsv };
		})
		.sort((a, b) => b.s - a.s);

	for (let innerX = 0; innerX < 12; innerX++) {
		for (let innerY = 0; innerY < 12; innerY++) {
			const idx = innerY + innerX * 12;
			const p = idx / 144;

			const colorIndex = Math.floor(p * (colors.length - 1));

			const color = colors[colorIndex];

			context.fillStyle = Color.stringify(color, 1);
			context.fillRect(x + 2 + innerX, y + 2 + innerY, 1, 1);
		}
	}
}

let atlas = null;

function drawAtlas(models) {
	const atlasCanvas = document.createElement('canvas');
	const atlasContext = atlasCanvas.getContext('2d');

	atlasCanvas.width = 512;
	atlasCanvas.height = 512;

	Object.values(models).forEach((model, index) => {
		const x = (index % 32) * 16;
		const y = Math.floor(index / 32) * 16;

		drawIcon(model, atlasContext, x, y);
	});

	return atlasCanvas;
}

export function getAtlas(models) {
	if (atlas === null) {
		const canvas = drawAtlas(models);
		atlas = canvas.toDataURL();
	}

	return atlas;
}

export function textureCoordinate(index) {
	const x = (index % 32) * 16;
	const y = Math.floor(index / 32) * 16;

	return [x, y];
}
