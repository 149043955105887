import Random from './random';

const random = Random.init('JRLxSPRPSxJK');

const title = 'Document'.split('');
title[0] = ' ' + title[0].toUpperCase();

const permutations = random.shuffle(permute(title));

function permute(arr) {
	const permutations = [];

	function swap(a, b) {
		const tmp = arr[a];
		arr[a] = arr[b];
		arr[b] = tmp;
	}

	function generate(n) {
		if (n === 1) {
			permutations.push([...arr]);
		} else {
			for (let i = 0; i < n; i++) {
				generate(n - 1);
				swap(n % 2 ? 0 : i, n - 1);
			}
		}
	}

	generate(arr.length);

	return permutations;
}

export default {
	generate: (n) => {
		const titles = permutations.slice(0, n).map((title) => title.join(''));

		return titles;
	},
};
